<template>
    <!--基本布局-->
    <div>
        <!--头部-->
        <layout-header></layout-header>
        <!--搜索-->
        <layout-search></layout-search>
        <!--导航-->
        <layout-nav></layout-nav>
        <!--路由-->
        <router-view />
        <!--服务-->

        <layout-seivice></layout-seivice>
        <!--底部-->
        <layout-footer></layout-footer>
        <!--固定-->
        <layout-fixed></layout-fixed>
    </div>
</template>

<script>
import LayoutHeader from './components/layout-header'
import LayoutSearch from './components/layout-search'
import LayoutNav from './components/layout-nav'
import LayoutSeivice from './components/layout-seivice'
import LayoutFooter from './components/layout-footer'
import LayoutFixed from './components/layout-fixed'

export default {
    name: 'BasicLayout',
    components: {
        LayoutHeader,
        LayoutSearch,
        LayoutNav,
        LayoutSeivice,
        LayoutFixed,
        LayoutFooter
    }
}
</script>

<style scoped></style>
